/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";
import styles from "./Header.scss";
import { HOME_PAGE_TYPE } from "./MetaHomePage";
import Logo from '../../assets/custom-assets/base64/logo';
import userIcon from '../../assets/custom-assets/new-ui/home_tab_my_click-3x.png';
import publicIcon from '../../assets/custom-assets/new-ui/home_tab_pub_nor-3x.png';

const CSS_TYPE = {
  1: 'left',
  2: 'right'
};

export function Header(props) {
  const { pageType, setPageType, isMobile } = props;
  const csstype = CSS_TYPE[HOME_PAGE_TYPE[pageType]];
  return (
    <div className={styles.headerContainer + (isMobile ? ' mobile' : '')}>
      <div className="header-logo-container">
        <img className="header-logo" src={Logo} />
      </div>
      <div className="contorller">
        <div className={`contorller-bg ${csstype}`} />
        <div onClick={() => setPageType('public')} className={`contorller-item ${csstype === 'left' ? 'active' : ''}`}>
          <img src={publicIcon} />
          广场
        </div>
        <div onClick={() => setPageType('user')} className={`contorller-item ${csstype === 'right' ? 'active' : ''}`}>
          <img src={userIcon} />
          我的
        </div>
      </div>
    </div>
  );
}
