/*
 * @Author: your name
 * @Date: 2022-03-22 16:28:56
 * @LastEditTime: 2022-04-06 11:26:22
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yuanyu/src/react-components/home/MetaHomePage.js
 */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import QrCode from './QrCode';
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { Header } from './Header';
import { SelectScenes } from './SelectScenes';
import checkIsMobile from "../../utils/is-mobile";
import GoToPCImg from '../../assets/custom-assets/gotopc.png';
import BackgroundImg from '../../assets/custom-assets/base64/bg';
import requiest from '../../utils/requiest';
import styles from "./MetaHomePage.scss";
import Login from "./Login";

export const HOME_PAGE_TYPE = {
  "public": 1,
  "user": 2
}

export function MetaHomePage() {
  const isMobile = checkIsMobile();

  const [pageType, setPageType] = useState('public');
  const yuanyuauthorization = window.localStorage.getItem('yuanyuauthorization');
  // if (!yuanyuauthorization) {
  //   return <Login>;
  // }
  // const [codeImage, setCodeImage] = useState('');
  // const [codeId, setCodeId] = useState('');

  // const getAuth = async () => {
  //   const { codeId, codeImage } = await requiest('loginWithWeb');
  //   setCodeId(codeId);
  //   setCodeImage(codeImage);
  // }

  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true);
    }

    // getAuth();
  }, []);

  // if (codeImage) {
  //   return <QrCode codeImage={codeImage} codeId={codeId} />
  // }

  // if (isMobile) {
  //   return <img
  //     style={{
  //       position: 'fixed',
  //       left: 0,
  //       right: 0,
  //       top: 0,
  //       bottom: 0,
  //       zIndex: 100,
  //     }}
  //     src={GoToPCImg}
  //   />
  // }

  return (
    <div
      // style={{ width: isMobile ? screenWidth : '' }}
      className={styles.indexPage + (isMobile ? ' mobile' : '')}
    >
      <img src={BackgroundImg} className="index-page-bg" />
      <Header isMobile={isMobile} pageType={pageType} setPageType={(type) => setPageType(type)} />
      <SelectScenes isMobile={isMobile} pageType={pageType} />
    </div>
  )
}
