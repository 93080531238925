/* eslint-disable prettier/prettier */

import React, { useState } from "react";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { HOME_PAGE_TYPE } from './MetaHomePage';
import Image from '../custom-components/Image';
import ENV from '../../env';
import styles from "./SelectScenes.scss";

const Scenes = [
  {
    id: "6wgL6z6",
    hub_id: 'TH8Wqbz',
    url: "https://assets.metaio.cc/assets/images/1361647261483_.pic_hd.jpg"
  },
  {
    id: "xBjZv9f",
    hub_id: 'cuSPdWy',
    url: "https://app.metaio.cc/files/8601a938-f48d-470a-be81-e9ecb483477b"
  },
  {
    id: "MPSN7XX",
    hub_id: '6twfBCX',
    url: "https://app.metaio.cc/files/d431b4ac-5440-4448-a6cc-49b9554f097b"
  },
  // {
  //   id: "HbfV6a8",
  //   hub_id: 'N7SjV9D',
  //   url: "https://app.metaio.cc/files/1af7e0a6-1a00-4c7e-9735-9efc361f5894"
  // },
  {
    id: "ZihccQj",
    hub_id: 'Tj2Rs7F',
    url: "https://app.metaio.cc/files/a214505c-40e2-462a-b292-3746e2aa7fe1"
  },
  {
    id: "i7MUDeN",
    hub_id: '72eiWFk',
    url: "https://app.metaio.cc/files/4a66e2dc-7b7a-490e-9028-879482b325f1"
  },
  {
    id: 'xtziA6d',
    hub_id: 'kakqykx',
    url: 'https://app.metaio.cc/files/f388a586-02a7-43e4-bcd1-f57f4045905d'
  },
  {
    id: '93gPXRL',
    hub_id: 'RSkwmRu',
    url: 'https://app.metaio.cc/files/19e85c41-dc09-4bb0-83d4-4951ac1f7724'
  },
  {
    id: '3LGawXk',
    hub_id: '5P9KSb6',
    url: 'https://app.metaio.cc/files/3ef31102-8e9b-44d4-ad4b-db0e249e3fc0'
  },
  {
    id: 'VqgfKPh',
    hub_id: 'ceXLfZh',
    url: 'https://app.metaio.cc/files/1a430c4d-ccd3-4c87-842c-083e93dfb494'
  },
  // {
  //   id: 'p3ZkPzc',
  //   url: ''
  // },
  // {
  //   id: 'nJxwwzy',
  //   url: ''
  // },
];
export function SelectScenes(props) {
  const { pageType, isMobile } = props;
  const [preCreate, setPreCreate] = useState(false);
  const toRoom = (id, create) => {
    if (create) {
      createAndRedirectToNewHub(null, id, false);
      return;
    }
    if (ENV === 'production') {
      window.location.href = `${window.location.origin}/${id}`;
    } else {
      window.location.href = `${window.location.origin}/hub.html?hub_id=${id}`;
    }
  }
  return (
    <div className={styles.selectScenesContainer + (isMobile ? ' mobile' : '')}>
      {(HOME_PAGE_TYPE[pageType] === HOME_PAGE_TYPE['user'] && preCreate) && <div className="select-secnen-title">请选择想要新建的房间</div>}
      {
        preCreate ? (
          <>
            {Scenes.map(secnen => (<Image onClick={() => toRoom(secnen.id, true)} src={secnen.url} key={secnen.id} className="scenes-item" />))}
          </>
        ) : HOME_PAGE_TYPE[pageType] === HOME_PAGE_TYPE['public'] ? (
          <>
            {Scenes.map(secnen => (<Image onClick={() => toRoom(secnen.hub_id, false)} src={secnen.url} key={secnen.id} className="scenes-item" />))}
          </>
        ) : (
          <div className="scenes-item add-item" onClick={() => setPreCreate(!preCreate)}>
            <div className="round">
              <div className="horizontal" />
              <div className="vertical" />
            </div>
          </div>
        )
      }
    </div>
  )
}
