import React, { useState } from 'react';
import LoadingIcon from "../../assets/custom-assets/base64/loading";

export default function (props) {
    const [loaded, setLoaded] = useState(false);
    const imgLoaded = () => {
        setLoaded(true);
    }
    const img = new Image();
    img.addEventListener("load", imgLoaded);
    img.src = props.src;

    if (!loaded) {
        return (
            <div
                {...props} 
                style={{
                    overflow: 'hidden',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img src={LoadingIcon} style={{width: '100%'}}/>
            </div>
        );
    } else {
        return <img {...props}  />
    }
}
